import React from 'react';
import {get, set, cloneDeep, filter, forEach, find, mapValues} from 'lodash';

import BaseAnswer from '../BaseAnswer';
import FractionPart from './FractionPart';
import IntegerPart from './IntegerPart';
import {processStringContent} from 'utils/content';


export default class Fraction extends BaseAnswer {
    defaultAnswer() {
        const items = get(this.props.component_data, 'data.items');
        const fractions = filter(items, (item) => item.type === 'fraction' || item.type === 'integer');
        const answer_selections = fractions.map((fractionData) => {
            return mapValues(fractionData.inputs, (inputData) => '');
        });
        return {
            text: '',
            answer_selections
        }
    }

    isValid() {
        const items = get(this.props.component_data, 'data.items');
        let isValid = true;
        const fractions = filter(items, (item) => item.type === 'fraction' || item.type === 'integer');
        forEach(fractions, (fraction, fractionKey) => {
            forEach(fraction.inputs, (inputData, inputKey) => {
                const v = get(this.state.answer, ['answer_selections', fractionKey, inputKey]);
                if (!v) {
                    isValid = false;
                }
            });
        });

        return isValid;
    }

    onChange(fractionKey) {
        return (fieldKey) => e => {
            e.preventDefault();
            const regex = /^[0-9]*$/;
            const v = e.target.value;
            if (v.match(regex)) {
                const newAnswerSelections = cloneDeep(this.state.answer.answer_selections);
                set(newAnswerSelections, [fractionKey, fieldKey], v);
                const newText = this.getAnswerText(newAnswerSelections);
                const newAnswer = {
                    text: newText,
                    answer_selections: newAnswerSelections
                }
                this.setState({answer: newAnswer});

                console.log('answer', newAnswer);
            }
        }
    }

    fractionPart(fractionData, fractionKey, inputKey, answer_selections) {
        const text = get(fractionData, ['texts', inputKey, 'content', 'text']);
        const answer = get(answer_selections, [fractionKey, inputKey], '');
        return text ?? answer;
    }

    getAnswerText(answer_selections) {
        const isNotEmpty = !!find(answer_selections, (fraction) => {
            const front = get(fraction, 'front');
            const top = get(fraction, 'top');
            const bottom = get(fraction, 'bottom');
            const integer_value = get(fraction, 'v');
            return !!front || !!top || !!bottom || !!integer_value;
        });
        if (!isNotEmpty) {
            return '';
        }

        const items = get(this.props.component_data, 'data.items', []);
        let fractionKey = -1;
        const textArray = items.map((item) => {
            if (item.type === 'fraction') {
                fractionKey++;
                // e.g. 5 frac{1}{2}
                const front = this.fractionPart(item, fractionKey, 'front', answer_selections);
                const top = this.fractionPart(item, fractionKey, 'top', answer_selections);
                const bottom = this.fractionPart(item, fractionKey, 'bottom', answer_selections);
                return `${front} frac{${top}}{${bottom}}`;
            } else if (item.type === 'integer') {
                fractionKey++;
                return this.fractionPart(item, fractionKey, 'v', answer_selections);
            } else {
                return get(item, 'content.text');
            }
        })
        return textArray.join('');
    }

    renderItem(itemData, fractionKey) {
        switch (itemData.type) {
            case 'hidden':
                return null;
            case 'static':
                const content = get(itemData, 'content.html');
                return (
                    <div className="cc-numberonly-item-static" style={itemData.style}>
                        {processStringContent(content)}
                    </div>
                );
            case 'fraction':
                return (
                    <FractionPart
                        component_data={this.props.component_data}
                        is_preview={this.props.is_preview}
                        answer={this.state.answer}
                        existing_answer={this.props.existing_answer}
                        onChange={this.onChange(fractionKey)}
                        itemData={itemData}
                        fractionKey={fractionKey}
                    />
                );
            case 'integer':
                return (
                    <IntegerPart
                        component_data={this.props.component_data}
                        is_preview={this.props.is_preview}
                        answer={this.state.answer}
                        existing_answer={this.props.existing_answer}
                        onChange={this.onChange(fractionKey)}
                        itemData={itemData}
                        fractionKey={fractionKey}
                    />
                );
            default:
                return null;
        }
    }

    renderAllItems() {
        const items = get(this.props.component_data, 'data.items', []);
        let fractionKey = -1;
        return items.map((itemData, itemKey) => {
            if (itemData.type === 'fraction' || itemData.type === 'integer') {
                fractionKey++;
            }
            return (
                <div key={itemKey}>
                    {this.renderItem(itemData, fractionKey)}
                </div>
            );
        });
    }

    render() {
        return (
            <div className="challenge-component-fraction">
                <div className="cc-fraction-description">
                    {`Enter a number in each box below.`}
                </div>

                <div className="flex-row flex-wrap flex-v-center">
                    {this.renderAllItems()}
                </div>
            </div>
        )
    }
}
