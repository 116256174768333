import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Droppable} from "react-beautiful-dnd"
import {get} from 'lodash'

import DnDDraggableItem from './DnDDraggableItem';


class AnswerOptions extends Component {
    getDroppableStyle() {
        return {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            // justifyContent: "center",
            minWidth: "100px",
            minHeight: "100px",
            padding: "0",
            background: "transparent",
            ...get(this.props.component_data, 'data.option_droppable_style')
        }
    }

    renderOptions() {
        const answerOptions = get(this.props.component_data, 'data.answer_options');
        const renderAnswerOptions = answerOptions.map((item, key) => {
            return (
                <div key={key}>
                    <DnDDraggableItem
                        is_preview={this.props.is_preview}
                        item={item}
                        option_index={key}
                    />
                </div>
            );
        });

        return (
            <div className="cc-dndpuzzle-answer-options">
                <div className="flex-row flex-box-10-10">
                    {renderAnswerOptions}
                </div>
            </div>
        );
    }

    render() {
        return (
            <Droppable
                droppableId={'option-droppable'}
                type={'123'}
                direction={'horizontal'}
            >
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={this.getDroppableStyle()}
                    >
                        {this.renderOptions()}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );
    }
}

AnswerOptions.defaultProps = {
}

AnswerOptions.propTypes = {
    component_data: PropTypes.object.isRequired,
    is_preview: PropTypes.bool.isRequired,
}

export default AnswerOptions