import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'

import {processStringContent} from 'utils/content'


class Link extends Component {
    getLink() {
        return get(this.props.component_data, 'data.link')
    }

    renderImage() {
        const image = get(this.props.component_data, 'data.image')
        if (image) {
            return (
                <div className="center-align">
                    <img src={image} />
                </div>
            )
        }
    }

    renderTitle() {
        const title = get(this.props.component_data, 'data.title')
        if (title) {
            return (
                <div className="center-align">
                    {processStringContent(title)}
                </div>
            )
        }
    }

    render() {
        return (
            <div className="challenge-component-link">
                <div className="flex-row flex-h-start">
                    <a className="component-link-a" href={this.getLink()} target="_blank">
                        {this.renderImage()}
                        {this.renderTitle()}
                    </a>
                </div>
            </div>
        )
    }
}

Link.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default Link
