import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import classNames from 'classnames';

import {SetStudentStreamCurrentIndexAction} from "../../actions/stream_actions";

export default function () {
    const dispatch = useDispatch();

    const stream = useSelector(state => state.stream);
    const current_index = useSelector(state => state.student_stream_current_index);
    const challenge_stream_entities = useSelector(state => state.challenge_version_stream_entities);

    console.log('stream', stream);
    console.log('challenge_stream_entities', challenge_stream_entities);

    const renderProgress = () => {
        const total = challenge_stream_entities.length - 1;
        const done = (current_index / total * 100).toFixed();

        return (
            <div className="ltb-progress flex-column flex-h-center">
                <div className="ltb-progress-text">
                    {`${done}% complete`}
                </div>
                <div className="ltb-progress-bar flex-row">
                    <div className="ltb-progress-done" style={{width: `${done}%`}}></div>
                </div>
            </div>
        );
    }

    const renderNav = () => {
        const render_page_labels = challenge_stream_entities.map((se, i) => {
            const class_name = classNames('ltb-nav-item flex-row flex-v-center', {
                'ltb-nav-item-active': i == current_index,
            });
            const onClick = () => {
                dispatch(SetStudentStreamCurrentIndexAction(i));
                window.scrollTo(0, 0);
            }
            return (
                <div className={class_name} key={se.id} onClick={onClick}>
                    <div className="ltb-nav-item-label">
                        {se.data.title ? se.data.title :'Introduction'}
                    </div>
                </div>
            );
        });

        return (
            <div className="ltb-nav">
                {render_page_labels}
            </div>
        );
    }

    return (
        <div className="left-tool-bar">
            {renderProgress()}

            {renderNav()}
        </div>
    );
}