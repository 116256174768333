import React from 'react';
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";
import {get, isEmpty} from 'lodash';

import BaseCard from './BaseCard';
import AnswerKeyButton from 'common/challenge/AnswerKeyButton';


class FinalCard extends BaseCard {
    getCardType() {
        return 'final'
    }

    getMinHeight() {
        return '400px'
    }

    renderIcon() {
        return (
            <div className="final-card-icon">
                <i className="far fa-thumbs-up fa-4x"/>
            </div>
        )
    }

    renderContent() {
        return (
            <div className="section">
                <h2 className="header-new center">
                    {`Bonus finished!`}
                </h2>
            </div>
        )
    }

    renderReturnToDashboard() {
        if (!this.props.is_preview) {
            const onClick = () => {
                this.props.navigate("/");
            }
            return (
                <div>
                    <button className="btn btn-large btn-challenge" onClick={onClick}>
                        <i className="fas fa-home"/>
                        <span>{`Home`}</span>
                    </button>
                </div>
            )
        }
    }

    renderUseAnswerKey() {
        if (!this.props.challenge_version.bonus_answer_key_url) {
            return null
        }

        const is_answer_keys_shown = get(this.props.lesson, 'is_answer_keys_shown')
        if (!isEmpty(this.props.lesson) && !is_answer_keys_shown) {
            return null
        }

        return (
            <div className="final-card-answer-key-message">
                {`Use the Answer Key to check your work.`}
            </div>
        )
    }

    renderBonusAnswerKey() {
        if (!this.props.challenge_version.bonus_answer_key_url) {
            return null
        }

        const is_answer_keys_shown = get(this.props.lesson, 'is_answer_keys_shown')
        if (!isEmpty(this.props.lesson) && !is_answer_keys_shown) {
            return null
        }

        return (
            <div>
                <AnswerKeyButton
                    challenge_version={this.props.challenge_version}
                    lesson={this.props.lesson}
                    is_preview={this.props.is_preview}
                    is_bonus={true}
                />
            </div>
        )
    }

    renderPDCertificateButton() {
        if (this.props.challenge_version.challenge_code.is_pd === true && !isEmpty(this.props.lesson)) {
            return (
                <a
                    href={`/teacher/pd/certificate`}
                    className='btn btn-large btn-on-white'
                    target='_blank'
                >
                    <i className="fas fa-download"/>
                    <span className='no-wrap'>Download Certificate</span>
                </a>
            );
        }

        return null;
    }

    renderOtherContent() {
        return (
            <div className="flex-column flex-vh-center">
                {this.renderIcon()}

                {this.renderContent()}

                {this.renderUseAnswerKey()}

                <div className="section">
                    <div className="flex-row flex-box-10-10 flex-wrap flex-h-center">
                        {this.renderBonusAnswerKey()}

                        {this.renderReturnToDashboard()}

                        {this.renderPDCertificateButton()}
                    </div>
                </div>
            </div>
        )
    }
}

FinalCard.propTypes = Object.assign({}, BaseCard.propTypes, {
    navigate: PropTypes.func.isRequired
});

export default props => (
    <FinalCard
        {...props}
        navigate={useNavigate()}
    />
);
