import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {first} from "lodash";
import {rolesConstants, SchoolDropdown, SetUserAction} from "mindsets-js-sdk";

const SchoolField = function ({validation_error_messages, setValidationErrorMessages, setMode}) {
    const dispatch = useDispatch();
    const curricula = useSelector(state => state.curricula)
    const user = useSelector(state => state.user);
    let user_new = JSON.parse(JSON.stringify(user));
    let validation_error_messages_new = JSON.parse(JSON.stringify(validation_error_messages));

    function onSchoolChoose(school) {
        if (school) {
            user_new.school_id = school.id;
            user_new.school = school;
            if (!user_new.state && school.state) {
                user_new.state = school.state;
                const curriculum = first(curricula.filter(curriculum => {
                    return curriculum.country_code === user_new.country && curriculum.state_code === school.state;
                }).sort((a, b) => {
                    if (a.is_default && !b.is_default) {
                        return -1;
                    } else if (!a.is_default && b.is_default) {
                        return 1;
                    }

                    return 0;
                }));
                if (curriculum) {
                    user_new.curriculum_id = curriculum.id;
                } else {
                    user_new.curriculum_id = null;
                }
            }
        } else {
            user_new.school_id = null;
            delete user_new.school;
        }
        dispatch(SetUserAction(user_new));
        setMode('user');
        delete validation_error_messages_new.school;
        setValidationErrorMessages(validation_error_messages_new);

        return school ? school.name : '';
    }

    if (user_new.persona === rolesConstants.ROLE_TEACHER) {
        return (
            <div className="col s12 m6">
                <div className="inputfield">
                    <SchoolDropdown
                        onSelect={onSchoolChoose}
                        label={(
                            <label htmlFor="school" className="flex-row">
                                <div>School *</div>
                                {
                                    validation_error_messages.hasOwnProperty('school') ?
                                        <i className="fas fa-exclamation-circle text-red ml-2"/> : null
                                }
                            </label>
                        )}
                        label_class='flex-row flex-box-2-5'
                        default_value={user_new.school ? user_new.school.name : ''}
                        with_district={true}
                        country={user_new.country}
                        renderAdd={() => (
                            <a
                                href={"#"}
                                className="autocomplete-dropdown-menu-foot"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setMode('school');
                                }}
                            >{`Can't find your school? Add it now`}</a>
                        )}
                        show_add_always={true}
                        container_class={validation_error_messages.hasOwnProperty('school') ? 'invalid' : ''}
                        state={user_new.state}
                        id='profile-update-school'
                        limit={100}
                        keep_open_if_not_selected={true}
                    />
                </div>
            </div>
        );
    }

    return null;
}

SchoolField.propTypes = {
    validation_error_messages: PropTypes.object.isRequired,
    setValidationErrorMessages: PropTypes.func.isRequired,
    setMode: PropTypes.func.isRequired
}

export default SchoolField;
