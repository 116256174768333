import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

import BaseCard from './BaseCard'
import {processStringContent} from 'utils/content'
import analytic from 'utils/Analytic'
import {isEducator} from 'utils/role'
import {getChallengeHeroImage} from 'utils/cloud'


class IntroCard extends BaseCard {
    getCardType() {
        return 'intro'
    }

    useChallengeColor() {
        return true
    }

    getHeroImage() {
        return getChallengeHeroImage(this.props.challenge_version.challenge_code.code)
    }

    renderFastFacts() {
        if (this.props.challenge_version && this.props.challenge_version.challenge_code && this.props.challenge_version.challenge_code.fact) {
            const onClick = () => {
                analytic.log('fastFactsDownloaded', {
                    name: this.props.challenge_version.name,
                    code: this.props.challenge_version.challenge_code.code
                })
            }
            return (
                <div className="challenge-intro-fast-facts">
                    <div className="flex-row">
                        <a className="link-new" href={this.props.challenge_version.challenge_code.fact} target="_blank"
                           onClick={onClick}>
                            <span>{`Fast Facts`}</span>
                            <i className="fas fa-file-download"></i>
                        </a>
                    </div>
                </div>
            )
        }
    }

    renderOverview() {
        if (this.props.challenge_version && this.props.challenge_version.overview) {
            return (
                <div className="challenge-intro-overview">
                    {processStringContent(this.props.challenge_version.overview)}
                </div>
            )
        }
    }

    renderTips() {
        if (this.props.me && this.props.challenge_version && this.props.challenge_version.tips && isEducator(this.props.me)) {
            return (
                <div className="challenge-intro-tips">
                    <div className="flex-row flex-box-2-5">
                        <div><strong>{`TIP:`}</strong></div>
                        {processStringContent(this.props.challenge_version.tips)}
                    </div>
                </div>
            )
        }
    }

    renderOtherContent() {
        return (
            <div className="flex-column">
                <div className="section">
                    <div className="challenge-intro-name">
                        {this.props.challenge_version.name}
                    </div>
                </div>

                <div className="section">
                    {this.renderFastFacts()}
                </div>

                <div className="section">
                    {this.renderOverview()}
                </div>

                <div className="section">
                    {this.renderTips()}
                </div>
            </div>
        )
    }
}

IntroCard.propTypes = Object.assign({}, BaseCard.propTypes, {
    me: PropTypes.object.isRequired
});

export default props => (<IntroCard{...props} me={useSelector(state => state.me)}/>);
