import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams, useSearchParams, useNavigate} from "react-router-dom";
import {filterConstants} from "mindsets-js-sdk";
import {get} from 'lodash';

import {
    LoadedInsightsDashboardAction,
    LoadInsightsDashboardAction
} from "../../actions/performance_actions";
import IndexPrintable from './indexPrintable';
import Template from './Template';
import analytic from 'utils/Analytic';

import "./insights-dashboard.scss";

export const achievement_demo = {
    type: 'TYPE 1',
    id: 'achievement_demo',
    title: 'Demo Student Achievement Dashboard',
    project: {
        id: 0,
        name: 'Demo',
        district: {
            state: ''
        }
    }
}
export const compare_demo = {
    type: 'TYPE 2',
    id: 'compare_demo',
    title: 'Demo Student Achievement Comparison Dashboard',
    project: {
        id: 0,
        name: 'Demo',
        district: {
            state: ''
        }
    }
}

export default function ({printable}) {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const dashboard = useSelector(state => state.insights_dashboard);
    const dashboard_filters = useSelector(state => state.dashboard_filters);
    const [searchParams, setSearchParams] = useSearchParams();
    const district_id = !isNaN(parseInt(searchParams.get('district_id'))) ? parseInt(searchParams.get('district_id')) : null;
    const me = useSelector(state => state.me);

    useEffect(() => {
        if (me) {
            if (!Object.keys(dashboard).length || dashboard.id !== parseInt(params.dashboard_id)) {
                const options = {};
                if (me.is_dashboard_super_admin && district_id) {
                    options['filters[district_id][0]'] = filterConstants.EXPRESSION_EQUAL + '=' + district_id;
                }
                if (params.dashboard_id === 'achievement_demo') {
                    achievement_demo.project.district.state = me.state;
                    if (me.school && me.school.state) {
                        achievement_demo.project.district.state = me.school.state;
                        if (me.school.district && me.school.district.state) {
                            achievement_demo.project.district.state = me.school.district.state;
                        }
                    }
                    if (['ca', 'co', 'fl', 'wa'].includes(achievement_demo.project.district.state.toLowerCase())) {
                        dispatch(LoadedInsightsDashboardAction({...achievement_demo}));
                    }
                } else if (params.dashboard_id === 'compare_demo') {
                    compare_demo.project.district.state = me.state;
                    if (me.school && me.school.state) {
                        compare_demo.project.district.state = me.school.state;
                        if (me.school.district && me.school.district.state) {
                            compare_demo.project.district.state = me.school.district.state;
                        }
                    }
                    if (['ca', 'co', 'fl', 'wa'].includes(compare_demo.project.district.state.toLowerCase())) {
                        dispatch(LoadedInsightsDashboardAction({...compare_demo}));
                    }
                } else {
                    dispatch(LoadInsightsDashboardAction(params.dashboard_id, options));
                }
            }
        }
    }, [me]);

    if (!Object.keys(dashboard).length || dashboard.id.toString() !== params.dashboard_id.toString()) {
        return null;
    }

    const renderPrintable = () => {
        const school_id = get(dashboard_filters, 'filter-1.school_id', []);
        const student_id = get(dashboard_filters, 'filter-1.student_id', []);

        if (school_id.length > 1 || student_id.length != 1) {
            return null;
        }

        const onClick = () => {
            analytic.log('insightsClickPrint', {
                dashboard_id: dashboard.id,
                dashboard_title: dashboard.title,
                project_id: dashboard.project.id,
                project_name: dashboard.project.name,
                dashboard_filters: dashboard_filters
            });

            const query_string = searchParams.toString();
            const url = `/insights/${params.dashboard_id}/printable` + (query_string ? `?${query_string}` : '');
            navigate(url);
        }

        return (
            <button
                className="btn btn-on-white"
                onClick={onClick}
            >
                {`Print/PDF`}
            </button>
        );
    }

    if (printable) {
        return (
            <IndexPrintable
                dashboard_id={params.dashboard_id}
                printable={printable}
            />
        );
    }

    return (
        <div id='insights-dashboard'>
            <div className="flex-row flex-v-center flex-between">
                <div className="section">
                    <h3>{dashboard.title}</h3>
                </div>

                <div>
                    {renderPrintable()}
                </div>
            </div>

            <Template
                dashboard_id={params.dashboard_id}
            />
        </div>
    );
}
