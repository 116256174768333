import React from 'react';
import {fill, get, set, clone, find, filter} from 'lodash';

import BaseAnswer from '../BaseAnswer';
import NumberOnlyField from './NumberOnlyField';
import {processStringContent} from 'utils/content';


export default class NumberOnly extends BaseAnswer {
    defaultAnswer() {
        const items = get(this.props.component_data, 'data.items');
        const inputItems = filter(items, {type: 'input'});
        const answer_selections = fill(Array(inputItems.length), '');
        return {
            text: '',
            answer_selections
        }
    }

    isValid() {
        const items = get(this.props.component_data, 'data.items');
        const inputItems = filter(items, {type: 'input'});
        const answer_selections = this.state.answer.answer_selections;
        if (answer_selections.length < inputItems.length) {
            return false;
        }
        const emptyOnes = filter(answer_selections, (v) => !v || v === '');
        if (emptyOnes.length > 0) {
            return false;
        }
        return true;
    }

    onChange(inputKey) {
        return e => {
            e.preventDefault();
            const regex = /^[0-9.+-]*$/;
            const v = e.target.value;
            if (v.match(regex)) {
                const newAnswerSelections = clone(this.state.answer.answer_selections);
                set(newAnswerSelections, inputKey, v);
                const newText = this.getAnswerText(newAnswerSelections);
                const newAnswer = {
                    text: newText,
                    answer_selections: newAnswerSelections
                }
                this.setState({answer: newAnswer});

                // console.log('answer', newAnswer);
            }
        }
    }
    
    getAnswerText(answer_selections) {
        const isNotEmpty = !!find(answer_selections, (v) => !!v);
        if (!isNotEmpty) {
            return '';
        }

        const items = get(this.props.component_data, 'data.items', []);
        let inputKey = -1;
        const textArray = items.map((item) => {
            if (item.type === 'input') {
                inputKey++;
                return get(answer_selections, inputKey, '');
            } else {
                return get(item, 'content.text');
            }
        })
        return textArray.join('');
    }

    renderItem(itemData, inputKey) {
        switch (itemData.type) {
            case 'hidden':
                return null;
            case 'static':
                const content = get(itemData, 'content.html');
                return (
                    <div className="cc-numberonly-item-static" style={itemData.style}>
                        {processStringContent(content)}
                    </div>
                );
            case 'input':
                return (
                    <NumberOnlyField
                        component_data={this.props.component_data}
                        is_preview={this.props.is_preview}
                        answer={this.state.answer}
                        existing_answer={this.props.existing_answer}
                        onChange={this.onChange(inputKey)}
                        itemData={itemData}
                        inputKey={inputKey}
                    />
                );
            default:
                return null;
        }
    }

    renderAllItems() {
        const items = get(this.props.component_data, 'data.items', []);
        let inputKey = -1;
        return items.map((itemData, itemKey) => {
            if (itemData.type === 'input') {
                inputKey++;
            }
            return (
                <div key={itemKey}>
                    {this.renderItem(itemData, inputKey)}
                </div>
            );
        });
    }

    render() {
        return (
            <div className="challenge-component-numberonly">
                <div className="cc-numberonly-description">
                    {`Enter a number in each box below.`}
                </div>

                <div className="flex-row flex-wrap flex-v-center">
                    {this.renderAllItems()}
                </div>
            </div>
        )
    }
}
